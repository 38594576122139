import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import Widget from "./widget"
import Footer from "./footer"
import StickyNavbar from "./menus/sticky-navbar"


const Layout = ({ 
  children, 
  hreflangs, 
  pages, 
  locale,
  bg = "/images/bg.jpg", 
  title = "Aktoploika.gr", 
  heading,
  description = "Αναζητήστε φθηνά ακτοπλοϊκά εισιτήρια για όλα τα Ελληνικά νησιά στις καλύτερες τιμές.", 
  mode = "full",
  home = {
    url: '/',
    title: 'Aktoploika.gr'
  },
  from,
  to
}) => {

  
  heading = heading || title;


  return (
    <>    
      {/* Navbar */}
      <StickyNavbar hreflangs={hreflangs} pages={pages} locale={locale} home={home}></StickyNavbar>

      {/* jumbotron */}
      { mode !== 'simple' &&
      <div className="container-fluid jumbotron-container">
          <div className="white-gradient"></div>
          <img src={bg} alt=""/>
          <div className="content-container">
            <h1 className="logo">{heading}</h1>
            <span className="page-description">{description}</span>
            <div className="widget-parent-container">
              <Widget locale={locale} from={from} to={to}></Widget>
            </div>
          </div>         
      </div>
      }
      
      {/* page content */}
      {children}  
        
      {/* footer */}
      <Footer locale={locale}></Footer>     

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
