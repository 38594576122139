import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
 
const LazyImage = ({ src, alt, className }) => (
  // <LazyLoadImage
  <img
    className={className}
    alt={alt}
    src={src} 
    // effect="blur"
  />
);
 
export default LazyImage;