import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import HomeIcon from "./home-icon"
import LanguageMenu from "./language-menu"
import menuIcon from './../../../static/images/menu.svg';

const StickyNavbar = ({ locale, pages, hreflangs, home }) => {
  pages = pages || []

  let [toggle, setToggle] = useState(false)
  let menuClassName = `central-menu ${toggle ? "open" : ""}`

  const handleToggleClick = () => setToggle(prevValue => !prevValue)

  return (
    <header className="fixed-header">
      <div className="secondary-menu">
        <HomeIcon></HomeIcon>
        <Link to={home.url}>{home.title}</Link>
      </div>

      <div className={menuClassName}>
        <img
          className="burger-icon"
          src={menuIcon}
          alt="aktoploika.gr menu icon"
          onClick={handleToggleClick}
        />
        <div className="menu-items">
          {pages.map((value, index) => {
            return (
              <Link to={value.url} key={index}>
                {value.title}
              </Link>
            )
          })}

          {toggle && (
            <LanguageMenu hreflangs={hreflangs}></LanguageMenu>
          )}
        </div>
      </div>
      
      <LanguageMenu hreflangs={hreflangs}></LanguageMenu>
    </header>
  )
}

StickyNavbar.propTypes = {
  siteTitle: PropTypes.string,
}

StickyNavbar.defaultProps = {
  siteTitle: ``,
}

export default StickyNavbar
