import React from "react"

const HomeIcon = () => {
  return (
      <span className="home-icon">
    <svg viewBox="0 0 60 58" >      
          <path
            id="Shape1"
            fill="#FF9900"
            d="m59.88 45.8857c-.2618971-.4861655-.8682911-.6680166-1.3545-.4062-4.2636398 2.4289912-9.1101171 3.6463593-14.0155 3.5205-5.3761557.1033295-10.6674916-1.3502216-15.2364-4.1855-4.1226725-2.5920008-8.9149103-3.9182139-13.7836-3.8145-6.8767 0-10.2224 1.853-13.7644 3.8149l-1.21.67c-.48324912.2675267-.65812668.8761508-.39059995 1.3594.26752672.4832491.8761508.6581267 1.35939995.3906l1.21-.67c3.31-1.8335 6.4356-3.5649 12.7956-3.5649s9.4966 1.7319 12.8179 3.5664c4.8594168 3.0110521 10.4866292 4.5509024 16.2021 4.4336 5.2375776.1340899 10.4121693-1.1661023 14.9643-3.76.4858625-.2620693.6674586-.86827.4057-1.3543z"
          />
          <path
            id="Shape2"
            d="m59.88 52.8857c-.2621611-.4858417-.8682489-.6676009-1.3545-.4062-4.2636398 2.4289912-9.1101171 3.6463593-14.0155 3.5205-5.3761557.1033295-10.6674916-1.3502216-15.2364-4.1855-4.1226725-2.5920008-8.9149103-3.9182139-13.7836-3.8145-6.8767 0-10.2224 1.853-13.7644 3.8149l-1.21.67c-.48324912.2675267-.65812668.8761508-.39059995 1.3594.26752672.4832491.8761508.6581267 1.35939995.3906l1.21-.67c3.31-1.8335 6.4356-3.5649 12.7956-3.5649s9.4966 1.7319 12.8179 3.5664c4.8594168 3.0110521 10.4866292 4.5509024 16.2021 4.4336 5.2375776.1340899 10.4121693-1.1661023 14.9643-3.76.4858625-.2620693.6674586-.86827.4057-1.3543z"
          />
          <path
            id="Shape3"
            d="m.12 39.1143c.2618971.4861655.86829111.6680166 1.3545.4062 3.17156332-1.8139439 6.68621909-2.9473182 10.32-3.3279.0069-.0011.0133.0016.02 0l.01-.0039c1.21759-.1282721 2.4411746-.1912622 3.6655-.1887 5.3761557-.1033295 10.6674916 1.3502216 15.2364 4.1855 4.1226725 2.5920008 8.9149103 3.9182139 13.7836 3.8145 6.8769 0 10.2226-1.853 13.7646-3.8149l1.21-.67c.4832492-.2675267.6581267-.8761508.3906-1.3594s-.8761508-.6581267-1.3594-.3906l-1.21.67c-2.69639 1.6862414-5.7061808 2.8092876-8.8481 3.3015.4041767-4.9833 1.4546162-9.892892 3.1249-14.6053.3744203-.9775721-.0679102-2.0785157-1.0146-2.5253l-3.568-1.6852v-11.9208c-.0018187-1.65610033-1.3438997-2.99818129-3-3h-9v-5c-.0018187-1.65610033-1.3438997-2.99818129-3-3h-4c-1.6561003.00181871-2.9981813 1.34389967-3 3v5h-9c-1.6561003.00181871-2.9981813 1.34389967-3 3v11.9209l-3.5645 1.6836c-.94810656.445568-1.39207187 1.5469771-1.018 2.5255.86712205 2.3462817 1.54266665 4.7589792 2.02 7.2144-3.48837677.5087513-6.85037379 1.6673167-9.9116 3.4156-.48593649.2619988-.66762746.8682173-.4059 1.3543zm26.88-36.1143c.0007162-.55198782.4480122-.99928381 1-1h4c.5519878.00071619.9992838.44801218 1 1v5h-6zm-12 8c.0007162-.5519878.4480122-.9992838 1-1h28c.5519878.0007162.9992838.4480122 1 1v10.9762l-14.1353-6.6762c-.5438862-.2609806-1.176537-.2624137-1.7216-.0039l-14.1431 6.6801zm-4.7124 15.4136 19.7158-9.3091 19.7124 9.3086c-1.7751228 5.0077374-2.8795813 10.2284105-3.2846 15.526-.6089.0374-1.2432.0609-1.9212.0609-6.3609 0-9.4966-1.7319-12.8179-3.5664-4.8594168-3.0110521-10.4866292-4.5509024-16.2021-4.4336-1.0878 0-2.0995.0468-3.0575.1248-.503889-2.624496-1.2212392-5.2034651-2.1449-7.7112z"
          />
    </svg>
    </span>
  )
}

export default HomeIcon
