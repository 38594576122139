import React from "react"
import { Link } from "gatsby"
import flagEl from './../../../static/images/flags/el.jpg'
import flagEn from './../../../static/images/flags/en.jpg'
import flagIt from './../../../static/images/flags/it.jpg'
import flagRu from './../../../static/images/flags/ru.jpg'

const LanguageMenu = ({ hreflangs = [] }) => {
  
  let languages = {
    el: hreflangs.find(l => l.locale === "el") || { url: "" },
    en: hreflangs.find(l => l.locale === "en") || { url: "/en/" },
    it: hreflangs.find(l => l.locale === "it") || { url: "/it/" },
    ru: hreflangs.find(l => l.locale === "ru") || { url: "/ru/" },
  }
  
  return (
    <div className="language-menu">
      <Link to={languages.el.url} className="language-link">
        <img src={flagEl} alt="el flag"></img>
      </Link>
      <Link to={languages.en.url} className="language-link">
        <img src={flagEn} alt="en flag"></img>
      </Link>
      <Link to={languages.it.url} className="language-link">
        <img src={flagIt} alt="it flag"></img>
      </Link>
      <Link to={languages.ru.url} className="language-link">
        <img src={flagRu} alt="ru flag"></img>
      </Link>
    </div>
  )
}

export default LanguageMenu
