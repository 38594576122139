import React from "react"
import { Link } from "gatsby"
import LazyImage from './LazyImage'
import logoEn from './../../static/images/logo_en.png';
import greeceLogo from './../../static/images/greece-logo.png';

const Footer = ({ locale = "en" }) => {
  let menus = {
    el: [
      {
        url: "/",
        title: "Ακτοπλοικα Εισητηρια",
      },
      {
        url: "/cyclades/",
        title: "Κυκλάδες",
      },
      {
        url: "/sporades/",
        title: "Σποράδες",
      },
      {
        url: "/dodecanese/",
        title: "Δωδεκάνησα",
      },
      {
        url: "/crete/",
        title: "Κρήτη",
      },
      {
        url: "/ionian/",
        title: "Επτάνησα",
      },
      {
        url: "/north-aegean/",
        title: "Βόρειο Αιγαίο",
      },
      {
        url: "/argosaronic/",
        title: "Αργοσαρωνικός",
      },
      {
        url: "/italy/",
        title: "Ιταλία",
      },
      {
        url: "/index_sealines.htm",
        title: "Ακτοπλοϊκές Εταιρείες",
      },
      {
        url: "/index_dromologia_ploion.htm",
        title: "Δρομολόγια Πλοίων",
      },
      {
        url: "/cars/index.htm",
        title: "Ενοικιάσεις Αυτοκινήτων",
      },
    ],
    en: [
      {
        url: "/en/",
        title: "Ferry Tickets Greece",
      },
      {
        url: "/en/cyclades/",
        title: "Cyclades",
      },
      {
        url: "/en/sporades/",
        title: "Sporades",
      },
      {
        url: "/en/dodecanese/",
        title: "Dodecanese",
      },
      {
        url: "/en/crete/",
        title: "Crete",
      },
      {
        url: "/en/ionian/",
        title: "Ionian",
      },
      {
        url: "/en/north-aegean/",
        title: "North Aegean",
      },
      {
        url: "/en/argosaronic/",
        title: "Argosaronic",
      },
      {
        url: "/en/italy/",
        title: "Italy",
      },
      {
        url: "/en/index_sealines.htm",
        title: "Ferry Companies",
      },
      {
        url: "/en/index_ferry_schedules.htm",
        title: "Ferry Schedules",
      },
      {
        url: "/en/cars/index.htm",
        title: "Car Rental",
      },
    ],
    it: [
      {
        url: "/it/",
        title: "Biglietti di Traghetto Grecia",
      },
      {
        url: "/it/cyclades/",
        title: "Cicladi",
      },
      {
        url: "/it/sporades/",
        title: "Sporadi",
      },
      {
        url: "/it/dodecanese/",
        title: "Dodecaneso",
      },
      {
        url: "/it/crete/",
        title: "Creta",
      },
      {
        url: "/it/ionian/",
        title: "Ionie",
      },
      {
        url: "/it/north-aegean/",
        title: "Nord Egeo",
      },
      {
        url: "/it/argosaronic/",
        title: "Argosaronico",
      },
      {
        url: "/it/italy/",
        title: "Italia - Grecia",
      },
      {
        url: "/it/index_sealines.htm",
        title: "Agenzie di Traghetto",
      },
      {
        url: "/it/index_ferry_schedules.htm",
        title: "Orari di Traghetti",
      },
    ],
    ru: [
      {
        url: "/ru/",
        title: "Билеты на паромы в Греции",
      },
      {
        url: "/ru/cyclades/",
        title: "Киклады",
      },
      {
        url: "/ru/sporades/",
        title: "Спорады",
      },
      {
        url: "/ru/dodecanese/",
        title: "Додеканис",
      },
      {
        url: "/ru/crete/",
        title: "Крит",
      },
      {
        url: "/ru/ionian/",
        title: "Ионические",
      },
      {
        url: "/ru/north-aegean/",
        title: "Северные Эгейские",
      },
      {
        url: "/ru/argosaronic/",
        title: "Саронический",
      },
      {
        url: "/ru/italy/",
        title: "Италия",
      },
      {
        url: "/ru/index_sealines.htm",
        title: "Паромные Компании",
      },
      {
        url: "/ru/index_ferry_schedules.htm",
        title: "Расписание Паромов",
      },
    ]
  };

  return (
    <footer className="footer">
      <div className="footer-menu">
        {menus[locale].map(item => {
          return <Link to={item.url} key={item.url} className="footer-menu--link">{item.title}</Link>
        })
        }
      </div>
      
      <div className="footer-images">
        <LazyImage alt="" src={logoEn} />
        <LazyImage alt="" src={greeceLogo} />
        {/* <img alt="aktoploika.gr horizontal logo" src="/wpimages/aktoploika-logo-horizontal.png" /> */}
        {/* <img alt="methodoi plirwmis eisitiriwn" src="/wpimages/payment-methods.png" />
        <img alt="methodoi plirwmis eisitiriwn" src="/wpimages/ferry-tickets-ferries.png" /> */}
      </div>
      
      <span className="copyright">
          Aktoploika.gr © 2009 - {new Date().getFullYear()}, All Rights Reserved
      </span>
      
    </footer>
  )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
