import React from "react"

const Widget = ({ locale = "en", options = "notitle,noborder", heading, from, to }) => {
  // replace ru locale with en
  if (locale === "ru") locale = "en"
  if (locale === "el") locale = "el"

  let url = `https://www.ferryhopper.com/${locale}/embed/small?aff_uid=aktpl&color=2d6790&options=${options}`

  if (from) {
    url = `${url}&from=${from}`;
  }

  if (to) {
    url = `${url}&to=${to}`;
  }

  return (
    <div className="widget-container">
      {heading && 
      <h1 className="text-center">{heading}</h1>
      }
      <iframe
        title="Ferryhopper aktoploika eisitiria"
        width="100%"
        height="350"
        scrolling="no"
        frameBorder="0"
        src={url}
        data-hj-allow-iframe=""
      ></iframe>
    </div>
  )
}

export default Widget
